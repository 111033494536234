/* --------------------------------------------------------------------------
| IMPORTS...
|-------------------------------------------------------------------------- */

import { timerElement, timerElementFrankWordSpeak, timerElementFrankWordSubscriber } from "../../variables/variables";
import { handleSelectScreensOnTimer } from "../../functions/functions-quoruns";
import { firstVerificationTime, handleCounter, handlePauseTimer, handlePlayAlarm, handleSetCounter, handleSetDescStatus, handleStartTimer, handleStopTimer, handleVerifyTimeStamp } from "../../functions/functions-timers";
import {
    handleCloseSubscribeTimer, handleInitalPauseTimerSubscribeFrankwordTimer,
    handleInitalStopTimerSubscribeFrankwordTimer, handleStartSpeakTimerTimer, handleStartSubscribeTimerTimer,
} from "../../functions/functions-frank-words";
import { handleCallSpeakFrankWordPanel, handleOpenFrankWordSpeakOnTimer, handleSetInformationCallCouncilorSpeakTimer } from "../../functions/functions-speaks";
import { handleFetch } from "../../functions/functions-helpers";
let interval;

/* --------------------------------------------------------------------------
| EVENTS FRANK WORD TIMER...
|-------------------------------------------------------------------------- */

const timerSubscribeFrankWordPausedCouncilor = timerElementFrankWordSubscriber.dataset.in_second
const timerSubscribeFrankWordStopedCouncilor = timerElementFrankWordSubscriber.dataset.is_stoped

let { sectionSubscribeTimer, sectionSpeakTimer, sectionPanelFrankWordSpeechRequest } = handleSelectScreensOnTimer();

addEventListener('load', function () {
    let { sectionSubscribeTimer, sectionSpeakTimer } = handleSelectScreensOnTimer();

    if (sectionSubscribeTimer.dataset.subscribe_frank_word_started) {
        handleStartSubscribeTimerTimer();
    }

    if (sectionSpeakTimer.dataset.speak_frank_word_started) {
        handleStartSpeakTimerTimer();
    }
});

if (timerSubscribeFrankWordPausedCouncilor) {
    handleInitalPauseTimerSubscribeFrankwordTimer(timerSubscribeFrankWordPausedCouncilor)
}

if (timerSubscribeFrankWordStopedCouncilor) {
    handleInitalStopTimerSubscribeFrankwordTimer(interval);
}

if (!timerElementFrankWordSubscriber.dataset.is_stoped && !timerElementFrankWordSubscriber.dataset.in_second && !sectionSpeakTimer.dataset.speak_frank_word_started && !sectionPanelFrankWordSpeechRequest.dataset.speech_request_started) {
    firstVerificationTime('FRANK_WORD_SUBSCRIBE');
    interval = setInterval(() => handleCounter('FRANK_WORD_SUBSCRIBE'), 1000);
}

if (sectionSpeakTimer.dataset.speak_frank_word_in_second) {
    clearInterval(interval);
    if (parseInt(sectionSpeakTimer.dataset.speak_frank_word_in_second) < 0) {
        handleSetDescStatus(false);
        handleSetCounter(-(sectionSpeakTimer.dataset.speak_frank_word_in_second))

    } else {
        handleSetDescStatus(true);
        handleSetCounter(sectionSpeakTimer.dataset.speak_frank_word_in_second)
    }
}

if (sectionSpeakTimer.dataset.speak_frank_word_started && !sectionSpeakTimer.dataset.speak_frank_word_in_second) {
    handleVerifyTimeStamp('/frank-word/syncronized-seconds/request').then(function () {
        interval = setInterval(() => handleCounter('FRANK_WORD_CALL'), 1000);
    });

}

window.Echo.channel("frank-word-subscribe-started")
    .listen(".frank-word-subscribe-started", (e) => {
        window.location.reload();
        handleStartSubscribeTimerTimer();
        clearInterval(interval);
        interval = setInterval(() => handleCounter('FRANK_WORD_SUBSCRIBE'), 1000);
    });

window.Echo.channel('frank-word-subscribe-time-restarted')
    .listen('.frank-word-subscribe-time-restarted', function () {
        window.location.reload();
    });

window.Echo.channel("frank-word-closed")
    .listen(".frank-word-closed", (e) => {
        handleCounter(0);
        handleCloseSubscribeTimer();
        clearInterval(interval);
        window.location.reload();
    });


/**speak */

window.Echo.channel("frank-word-added-more-minute-in-speak")
    .listen(".frank-word-added-more-minute-in-speak", async (e) => {
        handleSetDescStatus(e.desc);
        handleFetch('/frank-word/subscribe/syncronized-seconds');

    });

window.Echo.channel("frank-word-speak-councilor-called")
    .listen(".frank-word-speak-councilor-called", async (e) => {
        handleOpenFrankWordSpeakOnTimer();
        handleSetInformationCallCouncilorSpeakTimer(e);

        interval = await handleCallSpeakFrankWordPanel(interval);
    });

window.Echo.channel("frank-word-speak-councilor-paused")
    .listen(".frank-word-speak-councilor-paused", async (e) => {
        clearInterval(interval);
        if (parseInt(e.secondsPaused) > 0) {
            handleSetCounter(e.secondsPaused);
        } else {
            handleSetCounter(-e.secondsPaused);
        }
    });

window.Echo.channel("frank-word-speak-councilor-stoped")
    .listen(".frank-word-speak-councilor-stoped", async (e) => {
        clearInterval(await interval);
        handleSetCounter(0);
        location.reload();
    });

window.Echo.channel("synchronized-time-speak")
    .listen(".synchronized-time-speak", (e) => {
        if (e.synchronizedSeconds < 0) {
            handleSetDescStatus(false)
            handleSetCounter(-(e.synchronizedSeconds));
        } else {
            handleSetDescStatus(true)
            handleSetCounter(e.synchronizedSeconds);
        }
    });

window.Echo.channel("long-sound-played")
    .listen(".long-sound-played", (e) => {
        handlePlayAlarm('long');
    });

window.Echo.channel("short-sound-played")
    .listen(".short-sound-played", (e) => {
        handlePlayAlarm('short');
    });

window.Echo.channel("new-subscribe-added")
    .listen(".new-subscribe-added", (e) => {
        window.location.reload();
    });
