/*
|--------------------------------------------------------------------------
|-------------------------| FUNCTIONS SPEAKS |------------------------------
|--------------------------------------------------------------------------
*/

import { timerElementFrankWordSpeak } from '../variables/variables';
import { handleFetch, handleOpenScreenPagesOnPanel } from './functions-helpers';
import { handleSelectScreensOnPanel, handleSelectScreensOnTimer } from './functions-quoruns';
import { firstVerificationTime, handleCounter, handleSetCounter, handleSetDescStatus } from "./functions-timers";

/* --------------------------------------------------------------------------
| REMOVE OS ACTIVE DE TODOS OS TIMERS...
|-------------------------------------------------------------------------- */
export const handleClearTimerActive = (elements) => {
    elements.forEach((element) => {
        element.classList.remove('timer-speak--active');
    });
}

/* --------------------------------------------------------------------------
| PEGA O TIMER ESCOLHIDO E ADICIONA ACTIVE...
|-------------------------------------------------------------------------- */
export const handleSetTimerActive = (event) => {
    let current = event.currentTarget;
    current.classList.add('timer-speak--active');
}

/* --------------------------------------------------------------------------
| SELECIONA O TIMER ESCOLHIDO E SALVA NO SESSION STORAGE...
|-------------------------------------------------------------------------- */
export const handleSetTimeInSessionStorage = (event) => {
    let currentTarget = event.currentTarget;
    let dataTimeAttribute = currentTarget.dataset.time_amount;
    let frankWordSelectedId = currentTarget.id;

    sessionStorage.setItem('frank_word_time_selected', dataTimeAttribute);
    sessionStorage.setItem('frank_word_time_selected_id', frankWordSelectedId);
}

/* --------------------------------------------------------------------------
| FUNÇÃO QUE STARTA A FALA DO INSCRITO E INICIA O TIMER...
|-------------------------------------------------------------------------- */
export const handleCallSpeakFrankWordCouncilor = async (councilorUuid) => {
    let time;
    let inSecond = timerElementFrankWordSpeak.dataset.in_second;

    if (inSecond) {
        inSecond = parseInt(inSecond);

        if (inSecond > 0) {
            time = inSecond / 60;
            handleSetDescStatus(true);
        } else {
            time = -inSecond / 60
            handleSetDescStatus(false);
        }
    } else {
        time = sessionStorage.getItem('frank_word_time_selected');
    }

    let data = handleFetch(`/frank-word/councilor/${councilorUuid}/call?initial_time=${time}`);
    let timerComponent = document.querySelector('.timer--simple-variation');
    timerComponent.classList.remove('none-element');

    handleSetCounter(time * 60);
    let interval = setInterval(() => handleCounter('FRANK_WORD_CALL'), 1000);

    return interval;
}


/* --------------------------------------------------------------------------
| FUNÇÃO...
|-------------------------------------------------------------------------- */
export const handleOpenFrankWordSpeakOnPanel = () => {
    let sectionPanelFrankWordSpeak = document.querySelector('#section__panel__speak');
    handleOpenScreenPagesOnPanel(sectionPanelFrankWordSpeak);

    // let { sectionPanelHome, sectionPanelPropositionRead, sectionPanelPropositionVote, sectionPanelQuorum, sectionPanelFrankWordSpeak} = handleSelectScreensOnPanel();

    // if (sectionPanelFrankWordSpeak.classList.contains('none-element')) {
    //     sectionPanelHome.classList.add('none-element');
    //     sectionPanelPropositionRead.classList.add('none-element');
    //     sectionPanelPropositionVote.classList.add('none-element');
    //     sectionPanelQuorum.classList.add('none-element');
    //     sectionPanelFrankWordSpeak.classList.remove('none-element')
    // }
}

/* --------------------------------------------------------------------------
| FUNÇÃO...
|-------------------------------------------------------------------------- */
export const handleOpenFrankWordSpeakOnTimer = () => {
    let { sectionHome, sectionQuorum, sectionSubscribeTimer, sectionSpeakTimer } = handleSelectScreensOnTimer();

    if (sectionSpeakTimer.classList.contains('none-element')) {
        sectionHome.classList.add('none-element');
        sectionQuorum.classList.add('none-element');
        sectionSubscribeTimer.classList.add('none-element');
        sectionSpeakTimer.classList.remove('none-element');
    }
}

/* --------------------------------------------------------------------------
| FUNÇÃO...
|-------------------------------------------------------------------------- */
export const handleSetInformationCallCouncilorSpeak = (eventData) => {
    let { sectionPanelFrankWordSpeak } = handleSelectScreensOnPanel();
    let speakCouncilorName = sectionPanelFrankWordSpeak.querySelector('#speak_councilor_name');

    speakCouncilorName.innerHTML = eventData.councilor_called.name;
    window.location.reload();
}

/* --------------------------------------------------------------------------
| FUNÇÃO...
|-------------------------------------------------------------------------- */
export const handleSetInformationCallCouncilorSpeakTimer = (eventData) => {
    let { sectionSpeakTimer } = handleSelectScreensOnTimer();
    let speakCouncilorName = sectionSpeakTimer.querySelector('#speak_councilor_name');

    speakCouncilorName.innerHTML = eventData.councilor_called.name;
    window.location.reload();
}

/* --------------------------------------------------------------------------
| FUNÇÃO...
|-------------------------------------------------------------------------- */
export const handleCallSpeakFrankWordPanel = async (interval) => {
    let data = await handleFetch(`/frank-word/syncronized-seconds/request`);
    let timerComponent = document.querySelector('.timer--simple-variation');
    let time = data.synchronizedSeconds;

    handleSetCounter(time);
    interval = setInterval(() => handleCounter('FRANK_WORD_CALL'), 1000);

    return interval;
}

/* --------------------------------------------------------------------------
| FUNÇÃO...
|-------------------------------------------------------------------------- */
export const handleCallSpeakTimerFrankWordCouncilor = async (interval) => {
    let timerComponent = document.querySelector('.timer--simple-variation');

    firstVerificationTime('FRANK_WORD_CALL').then(function () {
        interval = setInterval(() => handleCounter('FRANK_WORD_CALL'), 1000);
    });

    return interval;
}
